/**
 * @description
 * Given a File, reads the file to determine if it is an heic. Returns a promise that when resuled will output a boolean.
 *
 * @param file {File} An HTML5 FileAPI File object
 * @returns {Promise}
 */
export async function isHeic(file) {
  return new Promise((resolve, reject) => {
    if (!file) {resolve(false);}
    try {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result?.slice(0, 100).includes('ftypheic'));
      reader.readAsText(file);
    } catch (e) {
      reject(e);
    }
  });
}

/**
 *
 * @param file
 * @returns {Promise<unknown>}
 */
export function loadFileAsText(file) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onload = event => resolve(event.target.result);
    fileReader.onerror = error => reject(error);
    fileReader.readAsText(file, 'UTF-8');
  });
}


export function mapUploadSource(source = '') {
  const sourceMap = {
    'Forever-v': 'Android',
    'com.forever.appstore': 'iOS',
    'Historian': 'Historian',
    'conversion': 'Digitization',
    'mars_ui': 'Web',
    'core_web_ui': 'Web',
    'Valet': 'Valet',
    'Facebook': 'Facebook Import',
    'Artisan': 'Artisan'
  };

  const key = Object.keys(sourceMap).find(key => source.includes(key));

  return key ? sourceMap[key] : 'Unknown';
}

export function getFilesWithDeclutterGroupData({files, interval}) {
  const dayjs = useDayjs();
  let currentGroup = {};
  let lastGroupFile = null;

  const isFileInCurrentGroup = file => {
    const lastGroupFileDate = dayjs(lastGroupFile.taken_at);
    const checkFileDate = dayjs(file.taken_at);

    return lastGroupFileDate.diff(checkFileDate, 'second', true) <= interval;
  }

  return files
    ?.map(file => {
      if (!lastGroupFile || !isFileInCurrentGroup(file)) {
        currentGroup = {
          id: `group-of-file-${file.id}`,
          get name() {
            return `${this.fileCount} Similar`;
          },
          fileCount: 0
        };
      }

      lastGroupFile = file;
      currentGroup.fileCount++;

      return Object.assign({}, file, {group: currentGroup});
    })
    .filter(file => file.group.fileCount > 1);
}
