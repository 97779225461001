import {FILE_CONTEXTS} from '~/constants/files.js';

const apiPaths = {
  file: '/users/:userId/forever_files/:fileId', //PUT
  files: '/users/:userId/forever_files/query', //POST
  counts: '/users/:userId/forever_files/query_count', //POST
  filesDetails: '/users/:userId/forever_files/details', //POST

  //batchFileDetails: '/users/:userId/forever_files/batch_details',
  updateMultiple: '/users/:userId/forever_files/update_multiple',
  deleteMultiple: '/users/:userId/forever_files/destroy_multiple',

  tagForeverFiles: '/users/:userId/tags/:tagId/forever_files/:fileId/tag_files',
  albumForeverFiles: '/users/:userId/albums/:albumId/forever_files/:fileId/album_files',
  deletedFilesPurge: '/deleted_files/purge',
  deletedFilesPurgeAll: '/deleted_files/purge_all',
  deletedFilesRestore: '/deleted_files/restore',
  disableShareToken: '/users/:userId/forever_files/:id/disable_share_token', //post
  resetShareToken: '/users/:userId/forever_files/:id/reset_share_token', //post
  copyMultiple: '/users/:userId/forever_files/copy_multiple',
  moveMultiple: '/users/:userId/forever_files/move',
  copyToCollaborativeAlbum: '/users/:userId/collaborative_album_uploads/:albumId/copy',
  optimizeVideoFile: '/users/:userId/video_files/:fileId/optimize',
  transcodeVideoFile: '/users/:userId/forever_files/:fileId/transcode',
  fileUrl: '/users/:userId/forever_files/:fileId/file_url',
  reorderFiles: '/users/:userId/albums/:albumId/reorder', //POST

  downloadMultiple: '/users/:userId/forever_files/download_multiple',
  downloadContext: '/users/:userId/:context/:id/download',

  addFilesToContext: '/users/:user_id/forever_files/add_to',
  removeFilesFromContext: '/users/:user_id/forever_files/remove_from'
};

export function useFileApi() {
  return {
    async getFiles({
      cursor,
      userId = useSessionStore().currentUserId,
      order,  //Object with a paramName prop with one of the values: 'date_taken ASC', 'date_taken DESC', 'upload_date ASC', 'upload_date DESC', 'name ASC', 'name DESC', 'custom'
      albumIds,
      tagIds,
      personIds,
      fileTypes,
      organization, //['favorites', 'not_in_album', 'not_tagged']
      startDate,
      endDate,
      addedSince,
      takenAtDateType,
      uploadSourceNames,
      search,
      contextType, //['album', 'tag', 'person', 'container']
      contextId,
      shareToken
    }) {

      const context = {
        type: contextType,
        id: contextId,
        share_token: shareToken
      };

      const filter = {
        person_ids: personIds,
        album_ids: albumIds,
        tag_ids: tagIds,
        file_types: fileTypes,
        organization: organization,
        start_date: startDate,
        end_date: endDate,
        since: addedSince,
        taken_at_date_type: takenAtDateType,
        upload_source_names: uploadSourceNames,
        order: order?.paramName,
      };

      const body = {
        cursor,
        context,
        search: search ? {q: search} : null,
        filter
      };


      const data = await useMarsApiV4().post(apiPaths.files, {
        params: {route: {userId}},
        body
      });

      data.files = data.forever_files.map(mapFile);
      delete data.forever_files;

      return data;
    },

    async getFilesCount({
      userId = useSessionStore().currentUserId,
      order,  //Object with a paramName prop with one of the values: 'date_taken ASC', 'date_taken DESC', 'upload_date ASC', 'upload_date DESC', 'name ASC', 'name DESC', 'custom'
      albumIds,
      tagIds,
      personIds,
      fileTypes,
      organization, //['favorites', 'not_in_album', 'not_tagged']
      startDate,
      endDate,
      addedSince,
      search,
      contextType, //['album', 'tag', 'person', 'container']
      contextId,
      shareToken}) {

        const context = {
          type: contextType,
          id: contextId,
          share_token: shareToken
        };

        const filter = {
          person_ids: personIds,
          album_ids: albumIds,
          tag_ids: tagIds,
          file_types: fileTypes,
          organization: organization,
          start_date: startDate,
          end_date: endDate,
          since: addedSince,
          order: order?.paramName,
        };

        const body = {
          context,
          search: search ? {q: search} : null,
          filter
        };

      const data = await useMarsApiV4().post(apiPaths.counts, {
        params: {route: {userId}},
        body
      });

      return data.count;
    },

    async getFileDetails(
      ids = [],
      {
        contextType,
        contextId,
        userId = useSessionStore().currentUserId,
        shareToken
      } = {}
    ) {

      const context = {
        share_token: shareToken || null
      };

      if (contextId) {
        context.type = contextType;
        context.id = contextId
      }

      const body = {
        context,
        forever_file_ids: ids
      };

      const data = await useMarsApiV4().post(
        apiPaths.filesDetails,
        {
          params: {route: {userId}},
          body
        }
      );

      return data.forever_files.map(setResponseFileType).map(file => mapFile(file, {isDetail: true}));
    },

    async updateFile({id, updates: {name, description, taken_at, taken_at_date_type, rotation, file_url, favorite}}) {
      const takenAt = (() => {
        if (taken_at && taken_at_date_type) {
          const fuzzyDate = useFuzzyDate();
          return fuzzyDate.getApiString({date: taken_at, type: taken_at_date_type});
        }
      })();

      const adjustedUpdates = {
        name,
        description,
        taken_at: takenAt,
        taken_at_date_type,
        rotation: rotation && (rotation % 360),
        file_url,
        favorite
      };

      const params = {
        route: {
          userId: useSessionStore().currentUserId,
          fileId: id
        }
      };

      const data = await useMarsApiV4().put(
        apiPaths.file,
        {
          params,
          body: adjustedUpdates
        }
      );

      return mapFile(Object.assign(setResponseFileType(data)), {isDetail: true});
    },

    async updateFiles({ids, updates: {albumIds, tagNames, personIds, name, description, date, dateType, favorite}}) {
      const payload = {ids};

      if (albumIds) {
        payload.album_ids = albumIds;
      }

      if (personIds) {
        payload.person_ids = personIds;
      }

      if (tagNames) {
        payload.tags = tagNames;
      }
      if (name) {
        payload.name = name;
      }
      if (description) {
        payload.description = description;
      }
      if (date) {
        const fuzzyDate = useFuzzyDate();

        payload.taken_at = (() => {
          if (date && dateType) {
            return fuzzyDate.getApiString({date, type: dateType || fuzzyDate.EXACT_DATE_AND_TIME});
          }
        })();

        payload.taken_at_date_type = dateType || fuzzyDate.EXACT_DATE_AND_TIME;
      }
      if (typeof favorite === 'boolean') {
        payload.favorite = favorite;
      }

      return useMarsApiV3().post(
        apiPaths.updateMultiple,
        {
          params: {
            route: {
              userId: useSessionStore().currentUserId
            }
          },
          body: payload
        }
      );
    },

    disableShareToken({id}) {
      return useMarsApiV3().post(
        apiPaths.disableShareToken,
        {
          params: {
            route: {
              id,
              userId: useSessionStore().currentUserId
            }
          }
        }
      );
    },

    async resetShareToken({id}) {
      const data = await useMarsApiV3().post(apiPaths.resetShareToken, {
        params: {
          route: {
            id,
            userId: useSessionStore().currentUserId
          }
        }
      });

      return data.share_token;
    },

    removeTagFromForeverFile({fileId, tagId}) {
      const params = {
        route: {
          userId: useSessionStore().currentUserId,
          tagId,
          fileId
        }
      };

      return useMarsApiV3().delete(apiPaths.tagForeverFiles, {params});
    },

    removeAlbumFromForeverFile({fileId, albumId}) {
      const params = {
        route: {
          userId: useSessionStore().currentUserId,
          albumId,
          fileId
        }
      };

      return useMarsApiV3().delete(apiPaths.albumForeverFiles, {params});
    },

    async copyFiles({ids, userId, shouldCopyTags}) {
      const params = {
        route: {userId},
        query: {
          copy_tags: shouldCopyTags
        }
      };

      return useMarsApiV3().post(
        apiPaths.copyMultiple,
        {
          params,
          body: {ids}
        }
      );
    },

    copyToCollaborativeAlbum({ids, albumId, userId, shareToken}) {
      return useMarsApiV3().post(
        apiPaths.copyToCollaborativeAlbum,
        {
          params: {
            route: {userId, albumId}
          },
          body: {
            share_token: shareToken,
            forever_file_ids: ids
          }
        }
      );
    },

    /**
     * @description - Adds files to a collection
     * @param {Array} ids - Array of file ids
     * @param {String} contextd - id of the collection
     * @param {String} contextType - Type of the collection (album, tag, person)
     * @returns {Promise}
     */
    async addFilesToContext({ids, contextId, contextType}) {
      const params = {
        route: {
          user_id: useSessionStore().currentUserId
        }
      };

      const body = {
        context: {
          type: contextType,
          id: contextId
        },
        forever_file_ids: ids
      };

      return useMarsApiV4().post(
        apiPaths.addFilesToContext,
        {
          params,
          body
        }
      );
    },

    /**
     * @description - Moves files from a source context to a destination context
     * @param {Array} ids - Array of file ids
     * @param {String} sourceContextId - id of the source collection
     * @param {String} sourceContextType - Type of the collection (album or tag)
     * @param {String} destinationContextId - id of the destination collection
     * @param {String} destinationContextType - Type of the collection (album or tag)
     * @returns {Promise}
     */
    async moveFiles({ids, sourceContextId, sourceContextType, destinationContextId, destinationContextType}) {
      const params = {
        route: {
          userId: useSessionStore().currentUserId
        }
      };
      const body = {
        forever_file_ids: ids,
        source_context: {
          type: sourceContextType,
          id: sourceContextId
        },
        destination_context: {
          type: destinationContextType,
          id: destinationContextId
        }
      };

      return useMarsApiV4().post(
        apiPaths.moveMultiple,
        {
          params,
          body
        }
      );
    },

    /**
     * @description - Removes files from a collection
     * @param {Array} ids - Array of file ids
     * @param {String} contextd - id of the collection
     * @param {String} contextType - Type of the collection (album, tag, person)
     * @returns {Promise}
     */
    async removeFilesFromContext({ids, contextId, contextType}) {
      const params = {
        route: {
          user_id: useSessionStore().currentUserId
        }
      };

      const body = {
        context: {
          type: contextType,
          id: contextId
        },
        forever_file_ids: ids
      };

      return useMarsApiV4().post(
        apiPaths.removeFilesFromContext,
        {
          params,
          body
        }
      );
    },

    async deleteFiles({ids}) {
      await useMarsApiV3().post(
        apiPaths.deleteMultiple,
        {
          params: {
            route: {userId: useSessionStore().currentUserId}
          },
          body: {ids}
        }
      );
      useSessionStore().getCurrentUser();
    },

    purgeDeletedFiles({ids}) {
      return useMarsApiV3().post(
        apiPaths.deletedFilesPurge,
        {
          body: {
            file_ids: ids
          }
        }
      );
    },
    reorderFiles(album, files, position) {
      const fileIds = files.map(f => f.id);

      const data = useMarsApiV4().post(
        apiPaths.reorderFiles,
        {
          params: {
            route: {
              userId: useSessionStore().currentUserId,
              albumId: album.id
            }
          },
          body: {
            forever_file_ids: fileIds,
            position
          }
        }
      );

      return data;
    },

    purgeAllDeletedFiles() {
      return useMarsApiV3().post(apiPaths.deletedFilesPurgeAll);
    },

    restoreFiles({ids}) {
      return useMarsApiV3().post(apiPaths.deletedFilesRestore, {
        body: {
          file_ids: ids
        }
      });
    },

    downloadFiles({ids, userId = useSessionStore().currentUserId, withExif}) {
      const params = {
        route: {userId}
      };

      return new Promise(async resolve => {
        if (withExif) {
          // await agent.post('downloadMultiple', {ids, with_exif: withExif}, params);

          await useMarsApiV3().post(
            apiPaths.downloadMultiple,
            {
              params,
              body: {ids, with_exif: withExif}
            }
          );
        } else {
          const url = useMarsApiV3().getFullUrl({path: apiPaths.downloadMultiple, params});

          //save files
          const fEl = document.createElement('form');

          fEl.method = 'POST';
          fEl.action = url;

          if (ids) {
            ids.forEach(id => {
              const iEl = document.createElement('input');
              iEl.type = 'hidden';
              iEl.name = 'ids[]';
              iEl.value = id;
              fEl.appendChild(iEl);
            });
          }

          document.body.appendChild(fEl);

          fEl.submit();

          setTimeout(() => {
            document.body.removeChild(fEl);
          }, 5000);
        }

        resolve();
      });
    },

    downloadContext({context, contextType, userId = useSessionStore().currentUserId, withExif}) {
      const ctx = (() => {
        switch (contextType) {
          case FILE_CONTEXTS.album:
            return 'albums';
          case FILE_CONTEXTS.tag:
            return 'tags';
          case FILE_CONTEXTS.container:
            return 'containers';
          case FILE_CONTEXTS.person:
            return 'people';
        }
      })();

      const params = {
        route: {
          id: context.id,
          userId,
          context: ctx
        },
        query: {
          with_exif: withExif
        }
      };

      return new Promise(async resolve => {
        if (withExif) {
          await useMarsApiV3().fetch(apiPaths.downloadContext, {params});
        } else {

          document.location = useMarsApiV3().getFullUrl({path: apiPaths.downloadContext, params});
        }
        resolve();
      });
    },

    transcodeVideoFile({fileId, userId = useSessionStore().currentUserId, shareToken, tagId, albumId, containerId}) {
      const payload = {
        share_token: shareToken,
        tag_id: tagId,
        container_id: containerId,
        album_id: albumId
      };

      return useMarsApiV3().post(
        apiPaths.transcodeVideoFile,
        {
          params: {route: {fileId, userId}},
          body: payload
        }
      );
    },

    //todo: verify this return object is compatible with v4 file api
    async optimizeVideoFile({fileId}) {
      const params = {
        route: {
          fileId,
          userId: useSessionStore().currentUserId
        }
      };

      const data = await useMarsApiV3().post(apiPaths.optimizeVideoFile, {params});

      return mapFile(Object.assign(setResponseFileType(data), {isDetail: true}));
    },

    async getFileUrl({fileId}) {
      const data = await useMarsApiV3().fetch(
        apiPaths.fileUrl,
        {
          params: {
            route: {
              userId: useSessionStore().currentUserId,
              fileId
            }
          }
        }
      );

      return data.file_url;
    }
  }
}

function setResponseFileType(data) {
  if (data.photo) {
    data.photo.fileType = FILE_TYPES.photo;
    return data.photo;
  } else if (data.project) {
    data.project.fileType = FILE_TYPES.project;
    return data.project;
  } else if (data.document) {
    data.document.fileType = FILE_TYPES.document;
    return data.document;
  } else if (data.video) {
    data.video.fileType = FILE_TYPES.video;
    return data.video;
  } else if (data.audio) {
    data.audio.fileType = FILE_TYPES.audio;
    return data.audio;
  }
}
