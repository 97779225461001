export const FUZZY_DATE_TYPES = {
  EXACT_DATE_AND_TIME: 'exact_date_and_time',
  SPECIFIC_DAY: 'specific_day',
  MONTH_AND_YEAR: 'month_and_year',
  QUARTER_AND_YEAR: 'quarter_and_year',
  YEAR: 'year',
  DECADE: 'decade',
  CENTURY: 'century'
};

export const FUZZY_DATE_LABELS = {
  [FUZZY_DATE_TYPES.EXACT_DATE_AND_TIME]: 'Exact Date & Time',
  [FUZZY_DATE_TYPES.SPECIFIC_DAY]: 'Specific Day',
  [FUZZY_DATE_TYPES.MONTH_AND_YEAR]: 'Month & Year',
  [FUZZY_DATE_TYPES.QUARTER_AND_YEAR]: 'Quarter & Year',
  [FUZZY_DATE_TYPES.YEAR]: 'Year',
  [FUZZY_DATE_TYPES.DECADE]: 'Decade',
  [FUZZY_DATE_TYPES.CENTURY]: 'Century'
}
