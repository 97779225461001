import {FUZZY_DATE_TYPES as types} from '~/constants/fuzzy-dates.js';

export function useFuzzyDate() {
  const dayjs = useDayjs();

  return {
    getFormattedString({date, type, includeDow}) {
      const dayjsDate = dayjs(date).utc();

      switch (type) {
        case types.SPECIFIC_DAY:
          return dayjsDate.format('LL');

        case types.MONTH_AND_YEAR:
          return dayjsDate.format('MMMM YYYY');

        case types.QUARTER_AND_YEAR:
          const quarter = getQuarter(dayjsDate);

          return `Q${quarter} ${dayjsDate.format('YYYY')}`;

        case types.YEAR:
          return dayjsDate.format('YYYY');

        case types.DECADE:
          return `${getDecadeStartYear(dayjsDate)}s`;

        case types.CENTURY:
          return getCenturySuffix(getCentury(dayjsDate));

        case types.EXACT_DATE_AND_TIME:
          return includeDow
            ? `${dayjsDate.format('LL')} \n ${dayjsDate.format('dddd')} ${dayjsDate.format('h:mm A')}`
            : `${dayjsDate.format('LL')} \n ${dayjsDate.format('h:mm A')}`;

          default:
            throw Error(`The date type ${type} is not supported.`);
      }
    },
    getApiString({date, type}) {
      const dayjsDate = dayjs(date).utc();

      switch (type) {
        case types.SPECIFIC_DAY:
          return dayjsDate.format('YYYY-MM-DD');

        case types.MONTH_AND_YEAR:
          return dayjsDate.format('YYYY-MM');

        case types.QUARTER_AND_YEAR:
          const quarter = getQuarter(dayjsDate);

          return `Q${quarter} ${dayjsDate.format('YYYY')}`;

        case types.YEAR:
          return `${dayjsDate.format('YYYY')}`;

        case types.DECADE:
          return `${getDecadeStartYear(dayjsDate)}`;

        case types.CENTURY:
          return `${getCentury(dayjsDate)}`;

        case types.EXACT_DATE_AND_TIME:
          return dayjsDate.toISOString();

        default:
          throw Error(`The date type ${type} is not supported.`);
      }
    },
    ...types
  }
}

function getQuarter(date) {
  const month = date.month();
  return Math.floor(month / 3) + 1;
}

function getDecadeStartYear(date) {
  return Math.floor(date.year() / 10) * 10
}

function getCentury(date) {
  return Math.ceil(date.year() / 100);
}
